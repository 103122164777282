.compare-plans-content {
  margin-top: 44px;
  padding-left: 48px;
  padding-right: 48px;

  .compare-plan-column {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }

  .compare-plan-barchart-container {
    height: 25vh;
    position: relative;
    display: contents;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    width: 100%;

    .stacked-bar-chart {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      flex-grow: 0;
      margin-right: 120px;
      overflow: hidden;
    }

    .compare-plans-asset-bar-chart {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    .stacked-bar-chart__items {
      width: 100% !important;
      position: absolute;
    }

    &__items {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100% !important;
      margin-top: 50px;
      overflow: hidden;
    }

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 2px;
      transition: opacity 0.3s;
      overflow: hidden;

      &--inactive {
        opacity: 0.5;
      }
    }

    &__fill {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &--selectable &__fill {
      cursor: pointer;
      outline: none;
      overflow: hidden;
    }
  }

  .compare-plan-barchart-row {
    display: flex;
    flex-flow: nowrap !important;

    &.compare-plans-barchart-row-underlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      right: 15px;
      width: 33.4%;
    }
  }

  .compare-plan-barchart-column {
    align-self: flex-end;
    margin-left: auto !important;
    margin-right: auto;
    padding-right: 5% !important;
    padding-left: 5% !important;
    height: 100% !important;
  }

  .compare-plan-columns {
    border: 1px solid #E0E0E0;
    border-right: none;
    height: 100%;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }

  .compare-plan2-border-right {
    border: 1px solid #E0E0E0 !important;
  }

  .accordion_table_row {
    display: flex;
    align-items: center !important;
    margin-left: -15px !important;
    height: 64px;
    font-weight: 400;
    align-content: center;
    width: 100%;
    flex-flow: nowrap !important;
  }

  .compare-plans-sub-header {
    font-family: Roboto, serif;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 12px;

    &.sub-title {
      text-align: center;
      padding-top: 16px;
    }
  }

  .bar-chart-legend-container {
    display: flex;
    flex-wrap: wrap;
  }

  .asset_insufficiency_empty_state {
    text-align: center;
    margin-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
    font-size: 18px;
    font-weight: 300;
  }

  .asset-reliance-barchart-legend-label {
    padding-left: 0;
    font-weight: 500;
  }

  .compare-plans-dropdown {
    padding-top: 5px;

    .dropdown__panel {
      z-index: 10;
    }

    .dropdown__panel .dropdown__item {
      white-space: normal;
    }

    label {
      font-weight: 500;
      color: #767676;
    }
  }
}

.divider {
  margin-bottom: -24px;
  margin-left: -1px;
  width: 0;
  border-left: 1px solid $color-nt-grey-100;
}

.three-dots-menu {
  .icon {
    font-size: 18px;
    font-weight: 400;
    color: white;
    padding-right: 0px;

    &:hover {
      color: $color-aqua-800;
      box-shadow: none;
    }
  }
}

.page-action-menu-options {
  display: flex;
  justify-content: space-evenly;
}

.compare-plans-pill-container {
  margin-left: 8px;
  width: auto;
  height: auto;
  padding: 0 8px 0 8px;
  border-radius: 100px 100px 100px 100px;
  display: inline-block;
  justify-content: center;
  align-items: center;

  .pill_text {
    color: var(--Colors-Orange-800, #AC5300);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.pill-container-shortfall {
  border: 2px solid #FFEEDE;
  background-color: #FFEEDE;
}

.pill-container-excess {
  border: 2px solid #E2FCE3;
  background-color: #E2FCE3;
}

.pill-container-row {
  display: contents;
}

.rendered-value-compare-plans {
  text-align: center;
  width: auto;
  display: inline-block;
}

.compare-plans-empty-state-hr {
  margin-top: -10px;
  width: 223%;
  position: sticky;
}
.compare-plans-hr {
  margin-left: -15
}

.compare-plans-empty-state {
  text-align: center;
  display: flex;
  width: 100%;
  top: -160px;
  font-family: Roboto;
  font-size: 18px;
  align-self: stretch;
  justify-content: center;
  align-items: center;
}

.row-container {
  display: flex;
  width: 100%;
}

.risk-donut-compare-plans {
  margin: 0 15px;
  align-items: center;
}

.risk-assets-compare-plans {
  margin: 0 15px;
  align-items: center;
  vertical-align: top;
  flex-wrap: nowrap;
}
.risk-donut-container {
  display:flex;
  align-items: center;
  justify-content: center;
}

.col-visual-borders {
  border-top: 1px solid #eaeaea;
  padding-top: 15px;
}

.row-styling {
  padding-right: 40px;
  margin-top: 10px;
  height: auto;
}

.compare-plans-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.compare-plans-asset-line {
  display: inline-block;
  left: 50%;
  margin: auto;
  z-index: 1;
  transform: translate(-50%);
  background: $color-sufficiency-positive;
  border-radius: 32px;
  width: 12px;
}

.compare-plans-asset-line-stack-one {
  display: inline-block;
  margin: auto;
  z-index: 1;
  left: 90%;
  transform: translate(-50%);
  background: $color-sufficiency-positive;
  border-radius: 32px;
  width: 12px;
}
.accordion-header{
  align-content: center;
  padding-left: 15px !important;
  text-align: left;
  border: 1px solid $color-nt-grey-100;
}
.accordion-column {
  text-align: center;
  align-content: center;
  border: 1px solid $color-nt-grey-100;

}
.row-styling-accordion {
  padding-right: 40px;
  margin-top: 10px;
  height: 70px;
}
.excess-accent{
  align-content: center;
  border: 1px solid $color-nt-grey-100;
  padding-left: 15px !important;
  text-align: left;
  border-left: 15px solid #7DE0E6;
}
.shortfall-accent{
  align-content: center;
  border: 1px solid $color-nt-grey-100;
  padding-left: 15px !important;
  text-align: left;
  border-left: 15px solid #E97C05;
}